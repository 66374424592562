<template>
  <div>
    <div class="mass-action-settings">
      <div>
        <el-input
          style="width: 500px"
          v-model="actionData.comment"
          type="textarea"
          :autosize="{ minRows: 4}"
          placeholder="Введите сообщение"
        ></el-input>
      </div>

      <div style="margin-top: 20px;">
        <el-popconfirm
          confirm-button-text='Да'
          cancel-button-text='нет'
          icon="el-icon-question"
          title="Вы уверены, что хотите создать комментарии?"
          @confirm="doAction"
        >
          <el-button
            slot="reference"
            size="small"
            type="primary"
          >
            Запустить
          </el-button>

        </el-popconfirm>
      </div>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect.vue";
import MassActionResult from "@/components/massActions/MassActionResult.vue";

export default {
  name: "card-admin-comment-action",
  mixins: [ActionTemplateMixin],
  components: {MassActionResult, CardsStageSelect},

  data() {
    return {
      actionData: {
        comment: '',
      }
    }
  },
  methods: {},
}
</script>
